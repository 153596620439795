body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background: #f3f5f9;
  overflow: hidden !important;
  font-size: 14px !important;
}

p {
  margin: 0;
}

.hidden {
  display: none !important;
}

// Marca um elemento como não clicável, força o cursos ter a aparência default.
.not-clikcable {
  cursor: default !important;
}

.hide-cancel-buttom {
  height: 0;
  .submit-container {
    top: -62px;
    position: relative;
  }
}