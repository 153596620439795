@use 'sass:math';
@use '@angular/material' as mat;

// Mixin utilizado para criar uma label flutuante.
// O código a seguir é uma cópia adaptada da forma que o Angular Material cria as labels dos form-fields.
@mixin astutus-float-label-theme($theme) {

  $foreground: map-get($theme, foreground);

  // Label colors. Required is used for the `*` star shown in the label.
  $label-color: mat.get-color-from-palette($foreground, secondary-text);

  $config: mat.define-legacy-typography-config();
  // The unit-less line-height from the font config.
  $line-height: mat.line-height($config, input);

  // The amount to scale the font for the floating label and subscript.
  $subscript-font-scale: 0.75;

  // The amount of space between the top of the line and the top of the actual text
  // (as a fraction of the font-size).
  $line-spacing: ($line-height - 1) / 2;
  // The padding on the infix. Mocks show half of the text size, but seem to measure from the edge
  // of the text itself, not the edge of the line; therefore we subtract off the line spacing.
  $infix-padding: 0.5em - $line-spacing;
  // The margin applied to the form-field-infix to reserve space for the floating label.
  $infix-margin-top: 1em * $line-height * $subscript-font-scale;

  .astutus-float-label {
    @include _label-floating(
                    $subscript-font-scale, $infix-padding, $infix-margin-top);
  }

  .astutus-float-label {
    color: $label-color;
    position: absolute;
    left: 0;
    font: inherit;
    pointer-events: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -ms-transform: none;
    transform-origin: 0 0;
    transition: transform .4s cubic-bezier(.25,.8,.25,1),
    color .4s cubic-bezier(.25,.8,.25,1),width .4s cubic-bezier(.25,.8,.25,1);
  }

  .mat-form-field-infix.astutus-float-label-container {
    flex: none;
    min-height: 32px;
    margin-bottom: 9.5px;
  }
}

// Used to make instances of the _mat-form-field-label-floating mixin negligibly different,
// and prevent Google's CSS Optimizer from collapsing the declarations. This is needed because some
// of the selectors contain pseudo-classes not recognized in all browsers. If a browser encounters
// an unknown pseudo-class it will discard the entire rule set.
$dedupe: 0;

// Applies a floating label above the form field control itself.
@mixin _label-floating($font-scale, $infix-padding, $infix-margin-top) {
  transform: translateY(-$infix-margin-top - $infix-padding + $dedupe)
             scale($font-scale);
  width: math.div(100%, $font-scale) + $dedupe;

  $dedupe: $dedupe + 0.00001 !global;
}