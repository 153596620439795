mat-horizontal-stepper.mat-stepper-horizontal-separate-body {
  background-color: transparent;
  margin: -12px;

  .mat-horizontal-stepper-header-container {
    background-color: #FFF;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0), 0 2px 2px 0 rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, .12);
  }

  .mat-horizontal-content-container {
    padding: 12px;

    .mat-horizontal-stepper-content {
      min-height: 50vh;
    }
    .mat-horizontal-stepper-content[aria-expanded=false] {
      min-height: unset;
    }
  }

  .mat-horizontal-stepper-header-container {
    .mat-step-header {
      .mat-step-label {
        font-weight: 500;
      }
    }
  }
}