//== Grid system
// Colunas do grid
$grid-columns: 12;

//Espaçamento entre os elementos
$element-margin: 8px;

//== Clors

$nexus-cloud-primary: #03a9f4;
$nexus-cloud-accent: #00bfa5;

$color-default: #b2b2b2;
$color-danger: #f44336;
$color-warn: #fbc02d;
$color-success: #4caf50;

//Menu colors
$hamburger-hover: rgba(255, 255, 255, 0.06);

$menu-headnavbar-backgorund-color: $nexus-cloud-primary;
$menu-background-color-nivel-1: #0277bd;
$menu-background-color-ativo: #0268a3;
$menu-hover-color: #0270b0;

//Menu size
$menu-base-width: 66px;
$menu-full-width: 348px;
$menu-spacing-right: 24px;
