@import "../abstracts/variables";

:root {
  --ag-icon-font-family: "agGridMaterial";
  --ag-border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

ag-grid-angular {
  display: flex !important;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 400px;
  width: 100%;
  border-bottom: var(--ag-border-bottom);
  color: #666;
  overflow: hidden;

  .ag-root.ag-layout-normal,
  .ag-root-wrapper-body.ag-layout-normal {
    height: unset;
  }

  .ag-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .ag-row-hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .ag-row-selected {
    border-bottom: 3px solid $nexus-cloud-accent !important;
  }

  .ag-header {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;

    .ag-header-cell {
      font-weight: 500;
    }
  }

  .header-decimal {
    direction: rtl;
  }

  .cell-decimal {
    text-align: right;
  }

  .ag-pinned-left-cols-container {
    .ag-row {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  .sortable {
    cursor: pointer !important;
  }

  .ag-body-container,
  .ag-header-row {
    width: 100% !important;
  }

  .ag-header-cell,
  .ag-header-group-cell,
  .ag-cell {
    padding-left: 20px;
    padding-right: 6px;
  }

  .ag-header-cell-comp-wrapper {
    width: unset;
  }

  .ag-header-group-cell {
    pointer-events: none;
  }

  .ag-row-dragging {
    cursor: -webkit-grabbing !important;
    background-color: #e5e5e5 !important;
  }

  .ag-cell {
    padding-top: 15px;
    border: none;
    -webkit-font-smoothing: antialiased;
  }

  .ag-overlay-no-rows-wrapper {
    background-color: white;
  }

  .ag-dnd-ghost {
    cursor: -webkit-grabbing !important;
  }
}
